import cn from 'classnames';
import styles from './SearchMobile.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearchActive, setSearchActive } from '@/store';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { fetchData } from '@/services/fetchData';
import debounce from 'lodash.debounce';
import { Button } from '../Button';
import { SearchResult, Variants } from '@/types';
import { useRouter } from 'next/router';
import { SearchItem } from '../SearchItem';
import { SearchNotFound } from '../SearchNotFound';
import { useTranslations } from '@/hooks/useTranslations';

type SearchProps = {
  variant: SearchVariant;
};

export enum SearchVariant {
  Dark = 'dark',
  Light = 'light',
}

export function SearchMobile({ variant }: SearchProps) {
  const searchActive = useSelector(selectSearchActive);

  const dispatch = useDispatch();
  const SearchRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState('');
  const [itemsList, setItemsList] = useState<SearchResult[]>([]);
  const [showResults, setShowResults] = useState(false);
  const router = useRouter();
  const translations = useTranslations();

  const activateSearch = () => {
    dispatch(setSearchActive(!searchActive));
  };

  const clearSearch = () => {
    setValue('');
    setItemsList([]);
    setShowResults(false);
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (SearchRef.current && !SearchRef.current.contains(target)) {
        dispatch(setSearchActive(false));
        setShowResults(false);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchActive) return;
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        const response = await fetchData({
          url: `/search/autocomplete?q=${value}`,
        });
        if (response.code === 200) {
          setItemsList(response.list);
          setShowResults(true);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchActive]);

  const debouncedFetchAutoComplete = useRef(
    debounce(async (query: string) => {
      const response = await fetchData({
        url: `/search/autocomplete?q=${query}`,
      });
      if (response.code === 200) {
        setItemsList(response.list);
        setShowResults(true);
      }
    }, 1000),
  ).current;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowResults(false);
    const value = event.target.value;
    setValue(value);
    if (value.length < 2) return;
    debouncedFetchAutoComplete(value);
  };

  const searchClassName = cn(styles.searchWrapper, {
    [styles.searchWrapperActive]: searchActive,
    [styles.searchWrapperLight]: variant === SearchVariant.Light,
  });
  const containerClassName = cn(styles.container, {
    [styles.containerActive]: searchActive,
  });

  const viewAll = () => {
    dispatch(setSearchActive(false));
    router.push(`/search?q=${value}`);
  };

  if (variant === SearchVariant.Dark)
    return (
      <div className={styles.wrapper} ref={SearchRef}>
        <div className={styles.searchButton} onClick={activateSearch}>
          <img loading="lazy" src="/svg/search.svg" alt="search" />
        </div>
        <div className={searchClassName}>
          <input
            type="text"
            className={styles.search}
            value={value}
            onChange={handleChange}
          />
          <img
            loading="lazy"
            src="/svg/clear.svg"
            width={24}
            height={24}
            alt="clear"
            className={styles.clear}
            onClick={clearSearch}
          />
        </div>
        {showResults && (
          <div className={styles.resultsWrapper}>
            <div className={styles.results}>
              {itemsList.length < 1 ? (
                <SearchNotFound query={value} />
              ) : (
                <>
                  {itemsList
                    .map((item) => (
                      <SearchItem
                        searchValue={value}
                        key={item.id}
                        name={item.name}
                        id={item.id}
                        section={item.section}
                        score={item.score}
                        text={item.text}
                        url={item.url}
                      />
                    ))
                    .slice(0, 4)}
                  <Button
                    variant={Variants.Ghost}
                    text={translations.searchAllResults}
                    onClick={viewAll}
                    className={styles.resultsButton}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );

  return (
    <div className={styles.wrapperLight} ref={SearchRef}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.icon}
        onClick={activateSearch}
      >
        <path
          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9999 20.9999L16.6499 16.6499"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className={containerClassName}>
        <div className={searchClassName}>
          <input
            type="text"
            className={styles.search}
            value={value}
            onChange={handleChange}
          />
          <img
            loading="lazy"
            src="/svg/clear.svg"
            width={24}
            height={24}
            alt="clear"
            className={styles.clear}
            onClick={clearSearch}
          />
        </div>
      </div>
      {showResults && searchActive && (
        <div className={styles.resultsWrapperLight}>
          <div className={styles.results}>
            {itemsList.length < 1 ? (
              <SearchNotFound query={value} />
            ) : (
              <>
                {itemsList
                  .map((item) => (
                    <SearchItem
                      searchValue={value}
                      key={item.id}
                      name={item.name}
                      id={item.id}
                      section={item.section}
                      score={item.score}
                      text={item.text}
                      url={item.url}
                    />
                  ))
                  .slice(0, 4)}
                <div className={styles.item}></div>
                <div className={styles.item}></div>
                <Button
                  variant={Variants.Ghost}
                  text="Все результаты поиска"
                  onClick={viewAll}
                  className={styles.resultsButton}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
