import { Palettes, Sizes, Variants } from '@/types';
import styles from './Button.module.scss';
import cn from 'classnames';
import { MouseEvent } from 'react';

type ButtonProps = {
  text: string;
  variant?: Variants;
  palette?: Palettes;
  size?: Sizes;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  tabIndex?: number;
  icon?: string;
};

export function Button({
  text,
  size = Sizes.Big,
  variant = Variants.Filled,
  palette = Palettes.Primary,
  onClick,
  disabled,
  className,
  type,
  tabIndex,
  icon,
}: ButtonProps) {
  const buttonClassName = cn(
    styles.button,
    {
      [styles.outline]: variant === Variants.Outline,
      [styles.ghost]: variant === Variants.Ghost,
      [styles.gray]: palette === Palettes.Secondary,
      [styles.small]: size === Sizes.Small,
      [styles.disabled]: disabled,
    },
    className,
  );

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (disabled) return;
    onClick();
  };

  return (
    <button
      className={buttonClassName}
      onClick={(e) => handleClick(e)}
      type={type}
      tabIndex={tabIndex}
    >
      {text}
      {icon && (
        <img loading="lazy" src={icon} alt="icon" className={styles.icon} />
      )}
    </button>
  );
}
